<template>
   <div>
    <div class="bar">
      <div v-for="article in articles" :key="article.id">
      <router-link :to="'/article/' + article.id">
      <article-card   
        :title="article.title"
        :excerp="article.excerp"
        :featuredImageUrl="article.featuredImageUrl"
        ></article-card>
      </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['articles']
}
</script>

<style scoped>
.bar div {
  margin: 5px;
}
a {
    text-decoration: none;
    color: black;
}
</style>