<template>
  <results-bar :matches="matches"></results-bar>
  <h1>Lista de Jugadores</h1>
  <div>
    <table>
      <tr>
        <th>Foto</th>
        <th>Nombre</th>
      </tr>
      <tr v-for="player in players" :key="player.id">
        <td><img :src="player.photo" /></td>
        <td><router-link :to="'/player/' + player.id">{{ player.firstName }} {{ player.lastName }}</router-link></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {

  data() {
    return {
      players: [],
      matches: []
    }
  },
  mounted() {
    this.getTeamPlayers()
    this.getTeamMatches()
  },
  methods: {
    getTeamPlayers() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/teams/${this.$route.params.id}/players`)
      .then(response => response.json())
      .then(data => (this.players = data));
    },
    getTeamMatches(){
       fetch(`${process.env.VUE_APP_PRIVATE_API}/teams/${this.$route.params.id}/matches`)
      .then(response => response.json())
      .then(data => (this.matches = data));
    } 
  }
}
</script>

<style scoped>
img {
  height: 50px;
}
</style>