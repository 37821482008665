<template>
  <div class="bar">
    <ul>
      <li v-for="school in schools" :key="school.id">
        <router-link :to="'/school/' + school.id"><img :src="school.logoUrl" /></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    props: ['schools']
}
</script>

<style scoped>
.bar {
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  vertical-align: middle;
  background-color: white;
}

img {
  height: 50px;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
li {
  margin: 5px;
  float: left;
}

</style>