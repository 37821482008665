<template>
   <div class="card">
    <h3>Próximos Partidos</h3>
    <div class="score-item" v-for="match in matches" :key="match.id">
      <img :src="match.homeSchoolLogo" alt="home logo"/>
      <div>
          <span class="datetime">{{ match.datetime }}</span>  
      </div>
     <img :src="match.awaySchoolLogo" alt="away logo" />
    </div>
  </div>
</template>

<style scoped>
.score-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

img {
  height: 70px;
}

.datetime {
  display: block;
  font-size: 12px;
}
.datetime {
  display: block;
  font-size: 12px;
  font-weight: bolder;
}

.card {
  display: block;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>

<script>
export default {
   props: ['matches']
}
</script>