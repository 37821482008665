<template>
  <div class="container">
    <div class="match">
      <div class="match-content">
        <div class="column">
          <div class="team team--home">
            <div class="team-logo">
              <img :src="homeLogoUrl" />
            </div>
            <router-link :to="'/team/' + homeTeamId"><h2 class="team-name">{{ homeTeamName }}</h2></router-link>
          </div>
        </div>
        <div class="column">
          <div class="match-details">
            <div class="match-date">{{ matchDate }}</div>

            <div class="match-score">
              <span class="match-score-number match-score-number--leading"
                >{{ homeScore }} </span
              >
              <span class="match-score-divider">:</span>
              <span class="match-score-number">{{ awayScore }}</span>
            </div>
            <div class="match-date">{{ location }}</div>
            <div class="match-date">Fase de Grupos</div>
            <router-link :to="'/match/' + matchId">Ver Partido</router-link>
          </div>
        </div>
        <div class="column">
          <div class="team team--away">
            <div class="team-logo">
              <img :src="awayLogoUrl" />
            </div>
            <router-link :to="'/team/' + awayTeamId"><h2 class="team-name">{{ awayTeamName }}</h2></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["homeTeamName", "awayTeamName", "matchDate", "location", "homeScore", "awayScore", "homeLogoUrl", "awayLogoUrl", "matchId", "homeTeamId", "awayTeamId"],
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

*,
*:after,
*:before {
  box-sizing: border-box;
}
:root {
  --color-text-primary: #1c2a38;
  --color-text-secondary: #8a8f98;
  --color-text-alert: #d72641;
  --color-text-icon: #dbdade;
  --color-bg-primary: #fff;
  --color-bg-secondary: #f3f5f9;
  --color-bg-alert: #fdeaec;
  --color-theme-primary: #623ce6;
}
button,
input,
select,
textarea {
  font: inherit;
}
img {
  display: block;
}
strong {
  font-weight: 600;
}
body {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  color: var(--color-text-primary);
  background-color: var(--color-bg-secondary);
}
.match {
  background-color: var(--color-bg-primary);
  display: flex;
  flex-direction: column;
  min-width: 600px;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(48, 48, 48, 0.1), 0 4px 4px 0 rgba(48, 48, 48, 0.1);
}
.match-header {
  display: flex;
  padding: 16px;
  border-bottom: 2px solid rgba(48, 48, 48, 0.1);
}
.match-status {
  background-color: var(--color-bg-alert);
  color: var(--color-text-alert);
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-right: auto;
}
.match-status:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: currentcolor;
  border-radius: 50%;
  margin-right: 8px;
}
.match-tournament {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.match-tournament img {
  width: 20px;
  margin-right: 12px;
}
.match-actions {
  display: flex;
  margin-left: auto;
}
.btn-icon {
  border: none;
  background-color: transparent;
  color: var(--color-text-icon);
  display: flex;
  align-items: center;
  justify-content: center;
}
.match-content {
  display: flex;
  position: relative;
}
.column {
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
}
.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team-logo {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-bg-primary);
  box-shadow: 0 4px 4px 0 rgba(48, 48, 48, 0.15),
    0 0 0 15px var(--color-bg-secondary);
}
.team-logo img {
  width: 50px;
}
.team-name {
  text-align: center;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 600;
}
.match-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.match-date,
.match-referee {
  font-size: 14px;
  color: var(--color-text-secondary);
}
.match-date strong,
.match-referee strong {
  color: var --color-text-primary;
}
.match-score {
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.match-score-number {
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
}
.match-score-number--leading {
  color: var(--color-text-primary);
}
.match-score-divider {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  color: var(--color-text-primary);
  margin-left: 10px;
  margin-right: 10px;
}
.match-time-lapsed {
  color: #df9443;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}
.match-referee {
  margin-top: 12px;
}
.match-bet-options {
  display: flex;
  margin-top: 8px;
  padding-bottom: 12px;
}
.match-bet-option {
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid var(--color-text-icon);
  background-color: #f9f9f9;
  border-radius: 2px;
  color: var(--color-text-secondary);
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
}
.container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>