import { createApp } from 'vue'
import App from './App.vue'
import router from "./router.js";

import ArticlesBar from './components/ArticlesBar.vue';
import ArticleCard from './components/ArticleCard.vue';
import MatchCard from './components/MatchCard.vue';
import ResultsBar from './components/ResultsBar.vue';
import NavBar from './components/NavBar.vue';
import SchoolsBar from './components/SchoolsBar.vue';
import MatchInfoBar from './components/MatchInfoBar.vue';
import SoccerLineup from './components/SoccerLineup.vue';
import ScoresCard from './components/ScoresCard.vue';
import ScheduleCard from './components/ScheduleCard.vue';
import AdPlaceholder from './components/AdPlaceholder.vue';
import LiveMatchCard from './components/LiveMatchCard.vue';

const app = createApp(App)
app.use(router);
app.component('scores-card', ScoresCard)
app.component('articles-bar', ArticlesBar)
app.component('match-card', MatchCard)
app.component('article-card', ArticleCard)
app.component('results-bar', ResultsBar)
app.component('nav-bar', NavBar)
app.component('schools-bar', SchoolsBar)
app.component('match-info-bar', MatchInfoBar)
app.component('soccer-lineup', SoccerLineup)
app.component('ad-placeholder', AdPlaceholder)
app.component('schedule-card', ScheduleCard)
app.component('live-match-card', LiveMatchCard)

app.mount('#app')
