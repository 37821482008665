<template>
   <div class="card">
    <h3>Últimos Resultados</h3>
    <div class="score-item" v-for="match in matches" :key="match.id">
      <img :src="match.homeSchoolLogo" />
      <div>
        <span class="event">{{ match.eventName }}</span>
        <span class="score">{{ match.homeScore }} : {{ match.awayScore }}</span>
         <router-link :to="'/match/' + match.id"><button>Repetición</button></router-link>
      </div>
     <img :src="match.awaySchoolLogo" />
    </div>
  </div>
</template>

<style scoped>
.score-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

img {
  height: 70px;
}

.event {
  display: block;
  font-size: 12px;
}
.score {
  display: block;
  font-size: 28px;
  font-weight: bolder;
}

.card {
  display: block;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>

<script>
export default {
   props: ['matches']
}
</script>