<template>
    <div class="formation">
       <div class="header">
           <div>IMG</div>
           <div>3-5-2</div>
       </div>
       
       <ul class="field">
           <li class="player pos1">
               <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#A5A6A7" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[0]?.number }}</text></svg>
               <span class="playerName">{{ players[0]?.playerLastName }}</span>
           </li>
            <li class="player pos2">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[1]?.number }}</text></svg>
               <span class="playerName">{{ players[1]?.playerLastName }}</span>
           </li>
            <li class="player pos3">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[2]?.number }}</text></svg>
               <span class="playerName">{{ players[2]?.playerLastName }}</span>
           </li>
            <li class="player pos4">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[3]?.number }}</text></svg>
               <span class="playerName">{{ players[3]?.playerLastName }}</span>
           </li>
            <li class="player pos5">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[4]?.number }}</text></svg>
               <span class="playerName">{{ players[4]?.playerLastName }}</span>
           </li>
            <li class="player pos6">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[5]?.number }}</text></svg>
               <span class="playerName">{{ players[5]?.playerLastName }}</span>
           </li>
            <li class="player pos7">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[6]?.number }}</text></svg>
               <span class="playerName">{{ players[6]?.playerLastName }}</span>
           </li>
            <li class="player pos8">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[7]?.number }}</text></svg>
               <span class="playerName">{{ players[7]?.playerLastName }}</span>
           </li>
            <li class="player pos9">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[8]?.number }}</text></svg>
               <span class="playerName">{{ players[8]?.playerLastName }}</span>
           </li>
            <li class="player pos10">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[9]?.number }}</text></svg>
               <span class="playerName">{{ players[9]?.playerLastName }}</span>
           </li>
            <li class="player pos11">
                <svg><radialGradient id="SVGID_1_" cx="-132.8827" cy="37.7079" r="26.1309" gradientTransform="matrix(0.92 0 0 0.3053 151.275 23.3399)" gradientUnits="userSpaceOnUse"><stop offset="0.1403" style="stop-color:#1A181D"></stop><stop offset="0.7601" style="stop-color:#1A181D;stop-opacity:0"></stop></radialGradient><ellipse opacity="0.4" fill="url(#SVGID_1_)" cx="29" cy="34.9" rx="23.8" ry="6.9"></ellipse><path fill="#F5B6CD" stroke="#FFFFFF" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><rect x="11.7" y="12.7" transform="matrix(0.891 0.454 -0.454 0.891 7.5123 -5.4519)" fill="#010101" width="6.9" height="0.4"></rect><rect x="41.3" y="9.5" transform="matrix(0.4535 0.8913 -0.8913 0.4535 34.193 -29.9195)" fill="#010101" width="0.4" height="6.9"></rect><path fill="#010101" d="M28.7,32.5h-0.9c-7.7,0-8.4-1.1-8.5-1.3l0.4-0.2c0,0,0.8,1.1,8.1,1.1h0.9c7.4,0,8.1-1,8.1-1.1l0.4,0.2C37.1,31.3,36.4,32.5,28.7,32.5z"></path><path fill="none" stroke="#010101" stroke-miterlimit="10" d="M40.2,3.9c-1-1.8-2.5-2-4-2.2s-4.5-1-4.7-1.1c-1.2-0.4-2.2,0.1-3.2,0.1c-1,0-2-0.5-3.2-0.1c-0.2,0.1-3.2,0.9-4.7,1.1c-1.5,0.2-3,0.4-4,2.2c-1,1.8-5.6,9.5-5.6,9.5l6,3l2.4-3.3c0,0,0.6,5.3,0.6,8.1s-0.7,9.5-0.3,12c0,0,0.4,1.2,8.3,1.2h0.9c7.9,0,8.3-1.2,8.3-1.2c0.4-2.5-0.2-9.2-0.2-12s0.6-8.1,0.6-8.1l2.4,3.3l6-3.1C45.8,13.4,41.2,5.7,40.2,3.9z"></path><text text-anchor="middle" transform="matrix(1 0 0 1 28 18.5)" fill="#000000" font-size="12">{{ players[10]?.number }}</text></svg>
               <span class="playerName">{{ players[10]?.playerLastName }}</span>
           </li>
       </ul>
    </div>
</template>

<script>
export default {
    props: ['players']
}
</script>

<style scoped>
.formation {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
}
.field {
    background: url(https://a.espncdn.com/redesign/assets/img/formations/ukcom-soccer-field-001.svg) 0 0 no-repeat;;
    display: block;
    height: 278px;
    width: 421px;
    list-style: none;
    margin: 0;
    padding: 0;
}
.player {
    margin: 0;
    padding: 0;
    z-index: 10;
    height: 55px;
    position: absolute;
}
.playerName {
    line-height: 1.5;
    width: 70px;
    bottom: 6px;
    left: 50%;
    color: white;
    font-size: 12px;
    text-align: center;
    transform: translateX(-50%);
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
svg {
    width: 55px;
    height: 100%;
    overflow: visible;
}
.pos1 {
    transform: translate(182px, 210px);
}
.pos2 {
    transform: translate(110px, 154px);
}
.pos3 {
    transform: translate(182px, 154px);
}
.pos4 {
    transform: translate(254px, 154px);
}
.pos5 {
    transform: translate(60px, 52px);
}
.pos6 {
    transform: translate(114px, 90px);
}
.pos7 {
    transform: translate(182px, 90px);
}
.pos8 {
    transform: translate(250px, 90px);
}
.pos9 {
    transform: translate(304px, 52px);
}
.pos10 {
    transform: translate(140px, -24px);
}
.pos11 {
    transform: translate(224px, -24px);
}
</style>