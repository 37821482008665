<template>
  <results-bar :matches="matches"></results-bar>
  <div>
    <h3>Lista de Equipos</h3>
    <ul>
      <li v-for="team in teams" :key="team.id">
        <router-link :to="'/team/' + team.id"> {{ team.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teams: [],
      matches: []
    }
  },
  mounted() {
    this.getSchool()
    this.getSchoolMatches();
  },
  methods: {
    getSchool() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/schools/${this.$route.params.id}/teams`)
      .then(response => response.json())
      .then(data => (this.teams = data));
    },
    getSchoolMatches(){
       fetch(`${process.env.VUE_APP_PRIVATE_API}/schools/${this.$route.params.id}/matches`)
      .then(response => response.json())
      .then(data => (this.matches = data));
    }
  }
}
</script>