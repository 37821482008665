<template>
  <div class="sport">
    <h1>Fútbol</h1>
  </div>
  <div class="schools">
      <schools-bar :schools="schools"></schools-bar>
  </div>
  <div class="container">    
    <div class="scores">
      <scores-card :matches="matches"></scores-card>
      <schedule-card :matches="matches"></schedule-card>
    </div>
    <div class="articles">
      <articles-bar :articles="articles"></articles-bar>
    </div>
    <div class="ads">
      <ad-placeholder />
      <ad-placeholder />
      <ad-placeholder />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schools: [],
      matches: [],
      articles: [],
    }
  },
  mounted() {
    this.getListSchools()
    this.getListMatches()
    this.getListArticles()
  },
  methods: {
    getListArticles() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/articles?sport=soccer`)
      .then(response => response.json())
      .then(data => (this.articles = data));
    },
    getListSchools() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/schools?sport=soccer`)
      .then(response => response.json())
      .then(data => (this.schools = data));
    },
    getListMatches(){
       fetch(`${process.env.VUE_APP_PRIVATE_API}/matches?sport=soccer`)
      .then(response => response.json())
      .then(data => (this.matches = data));
    }
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 300px auto 250px;
  align-items: initial;
  column-gap: 30px;
  row-gap: 15px;
}

.scores {
  max-width: 300px;
}
.articles {
  max-width: 600px;
}
.ads {
  display: grid;
  max-height: 800px;
}

</style>