<template>
  <div class="card">
    <div class="header">
      <div class="liveBug">
        <div class="circle"></div>
        <div>EN VIVO</div>
      </div>
      <div class="eventName">Fútbol Masculino Sub13</div>
      <div class="stageName">Fase de Grupos</div>
    </div>
    <div class="content">
      <div class="homeTeam">
        <img
          class="teamLogo"
          src="https://livecommerce.sportscam.pe/private/logos/inmaculada.png"
        />
      </div>
      <div class="score">
        <div class="scoreCard">3</div>
        <div class="scoreCard">2</div>
        <button class="cta">Ver Partido</button>
      </div>
      <div class="awayTeam">
        <img
          class="teamLogo"
          src="https://livecommerce.sportscam.pe/private/logos/santa_maria.png"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  box-sizing: content-box;
  font-family: Arial, Helvetica, sans-serif;
  height: 250px;
  width: 500px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 125px 125px 125px 125px;
  grid-template-rows: 50px 200px;
}
.header {
  grid-column-start: 1;
  grid-column-end: end;
  grid-row-start: 1;
  grid-row-end: 2;
  text-align: center;
  display: grid;
  grid-template-columns: 50px 150px 250px 50px;
  grid-template-rows: 25px 25px;
  background-color: lightgrey;
  border-radius: 20px 20px 0px 0px;
}
.circle {
  width: 16px;
  height: 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: red;
}
.liveBug {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: end;
  color: black;
  font-weight: bolder;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.eventName {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: end;
  line-height: 25px;
  font-weight: bolder;
}
.stageName {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: end;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}
.content {
  background-color: white;
  grid-column-start: 1;
  grid-column-end: end;
  grid-row-start: 2;
  grid-row-end: 3;
  display: grid;
  grid-template-columns: 150px 200px 150px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  justify-items: center;
  align-items: center;
  text-align: center;
}
.homeTeam {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  align-self: center;
}
.awayTeam {
  grid-column-start: 3;
  grid-column-end: end;
  grid-row-start: 1;
  grid-row-end: 2;
  align-self: center;
}
.teamLogo {
  width: 120px;
  height: 120px;
}
.score {
  grid-column-start: 2;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: 100px 100px;
  row-gap: 10px;
  column-gap: 5px;
  align-items: center;
  justify-items: center;
}
.homeScore {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
}
.awayScore {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: end;
}
.cta {
  grid-column-start: 1;
  grid-column-end: end;
  grid-row-start: 2;
  grid-row-end: end;
  width: 150px;
  height: 40px;
  background-color: gray;
  color: white;
  border-radius: 5px;
  justify-self: center;
  align-self: center;
}
.scoreCard {
  background-color: black;
  color: white;
  font-weight: bolder;
  font-size: 60px;
  width: 80px;
  height: 90px;
  align-items: center;
  justify-items: center;
  border-radius: 10px;
  border: 1px solid black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>