<template>
  <div class="schools">
    <schools-bar :schools="schools"></schools-bar>
  </div>
  <div>
    <ul>
      <li v-for="team in teams" :key="team.id">
        <router-link :to="'/team/' + team.id"> {{ team.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
      teams: {}
    }
  },
  mounted() {
    this.getSchool()
  },
  methods: {
    getSchool() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/schools/${this.$route.params.id}/teams`)
      .then(response => response.json())
      .then(data => (this.teams = data));
    } 
  }
}
</script>