<template>
    <div class="ad">
        Ad
    </div>
</template>

<style scoped>
.ad {
    height: 250px;
    widows: 250px;
    background-color: gray;
    color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>