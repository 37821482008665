<template>
   <div>
    <div v-for="match in matches" :key="match.id">
    <match-card   
      :matchId="match.id"
      :homeScore="match.homeScore"
      :awayScore="match.awayScore"
      :homeTeamId="match.homeTeamId"
      :awayTeamId="match.awayTeamId"
      :homeTeamName="match.homeTeamName"
      :awayTeamName="match.awayTeamName"
      :location="match.location"
      :matchDate="match.datetime"
      :eventName="match.eventName"
      :eventStageName="match.eventStageName"
      :homeLogoUrl="match.homeSchoolLogo"
      :awayLogoUrl="match.awaySchoolLogo"
      ></match-card>
    </div>
  </div>
</template>

<script>
export default {
    props: ['matches']
}
</script>