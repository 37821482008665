<template>

  <div class="live">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/rxslQS6KTS4?autoplay=1" title="YouTube video player" frameborder="0" autoplay="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <live-match-card />
  </div>
  <div class="schools">
      <schools-bar :schools="schools"></schools-bar>
  </div>
  <div class="container">    
    <div class="scores">
      <scores-card :matches="matches"></scores-card>
      <schedule-card :matches="matches"></schedule-card>
    </div>
    <div class="articles">
      <articles-bar :articles="articles"></articles-bar>
    </div>
    <div class="ads">
      <ad-placeholder />
      <ad-placeholder />
      <ad-placeholder />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schools: [],
      matches: [],
      articles: [],
    }
  },
  mounted() {
    this.getListSchools()
    this.getListMatches()
    this.getListArticles()
  },
  methods: {
    getListArticles() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/articles`)
      .then(response => response.json())
      .then(data => (this.articles = data));
    },
    getListSchools() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/schools`)
      .then(response => response.json())
      .then(data => (this.schools = data));
    },
    getListMatches(){
       fetch(`${process.env.VUE_APP_PRIVATE_API}/matches`)
      .then(response => response.json())
      .then(data => (this.matches = data));
    }
  }
}
</script>

<style scoped>
.live {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black;
  display: flex;
  flex-flow: row;
  column-gap: 20px;
  align-items: center;
  justify-content: center;

}

.live-content {
  display: flex;
  align-items: center;
}

.live-player {
  width: 700px;
}

.live-info {
  display: grid;
  grid-auto-rows: 80px;
  width: 500px;
  height: 315px;
  background-color: white;
  color: black;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.info-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.info-match {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.schoolLogo img {
  height: 100px;
}

.container {
  display: grid;
  grid-template-columns: 300px auto 250px;
  align-items: initial;
  column-gap: 30px;
  row-gap: 15px;
}

.scores {
  max-width: 300px;
}
.articles {
  max-width: 600px;
}
.ads {
  display: grid;
  max-height: 800px;
}

</style>