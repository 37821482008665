<template>
  <div class="card">
    <div class="card-header">
      <img :src="player.photo" />
      <h1 class="player-name">{{ player.firstName }} {{ player.lastName }}</h1>
    </div>
    <div class="card-info">
      <div class="row">
        <div class="column">Género: {{ getGender(player.gender) }}</div>
        <div class="column">Edad: {{ getAge(player.birthday) }}</div>
      </div>
      <div class="row">
        <div class="column">Peso: {{ player.weight }} Kg</div>
        <div class="column">Talla: {{ getHeightInMeters(player.height) }} m</div>
      </div>
      <div class="row">
        <div class="column">Eventos: {{ player.events }}</div>
        <div class="column">Colegio: <router-link :to="'/school/' + player.schoolId">{{ player.schoolName }}</router-link></div>
      </div>
    </div>
  </div>
  <results-bar :matches="matches"></results-bar>
</template>

<style scoped>
.card {
  display: block;
  justify-content: center;
  max-width: 400px;
  margin: auto;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

img {
  display:block;
  margin:auto;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}
</style>

<script>

export default {
  data() {
    return {
      player: {},
      matches: []
    }
  },
  mounted() {
    this.getPlayer()
    this.getPlayerMatches()
  },
  methods: {
    getAge(birthday) {
      var currentDate = new Date();
      var birthDate = new Date(birthday);
      var diff = currentDate - birthDate
      var age = Math.floor(diff/31557600000)
      return age;
    },
    getHeightInMeters(heightInCm) {
      return heightInCm/100
    },
    getGender(gender) {
      return (gender === 'M' ? 'Masculino' : 'Femenino')
    },
    getPlayer() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/players/${this.$route.params.id}`)
      .then(response => response.json())
      .then(data => (this.player = data));
    },
    getPlayerMatches(){
       fetch(`${process.env.VUE_APP_PRIVATE_API}/players/${this.$route.params.id}/matches`)
      .then(response => response.json())
      .then(data => (this.matches = data));
    } 
  }
}
</script>
