<template>
  <h1>Lista de Jugadores</h1>
  <div>
      <ul>
         <li v-for="player in players" :key="player.id">
             <router-link :to="'/player/' + player.id"> {{ player.name }}</router-link>
        </li>
      </ul>
  </div>
</template>

<script>

export default {

  data() {
    return {
      players: []
    }
  },
  mounted() {
    this.getListPlayers()
  },
  methods: {
    getListPlayers() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/players`)
      .then(response => response.json())
      .then(data => (this.players = data));
    } 
  }
}
</script>
