<template>
  <nav class="menu">
    <router-link :to="'/'"><img src="@/assets/SC_Logo_White.png"/></router-link>
    <ul>
      <li><router-link :to="'/schools'">Colegios</router-link></li>
      <li><router-link :to="'/soccer'">Fútbol</router-link></li>
      <li><router-link :to="'/basketball'">Básquet</router-link></li>
      <li><router-link :to="'/volleyball'">Voley</router-link></li>
    </ul>
  </nav>
</template>

<style scoped>
.menu {
  background-color: black;
  color: white;
  height: 60px;
}

img {
  float: left;
  height: 60px;
}

ul {
  list-style-type: none;
}
li {
  float: left;
}

li a {
  height: 60px;
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  color: black;
  background-color: white;
}
</style>