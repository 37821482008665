<template>
  <div class="schools">
    <schools-bar :schools="schools"></schools-bar>
  </div>
  <match-card   
      :matchId="match.id"
      :homeScore="match.homeScore"
      :awayScore="match.awayScore"
      :homeTeamName="match.homeTeamName"
      :awayTeamName="match.awayTeamName"
      :location="match.location"
      :matchDate="match.datetime"
      :eventName="match.eventName"
      :eventStageName="match.eventStageName"
      :homeLogoUrl="match.homeSchoolLogo"
      :awayLogoUrl="match.awaySchoolLogo"
      ></match-card>
      <div class="player">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/rxslQS6KTS4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
</template>

<script>
export default {
  data() {
    return {
      match: {}
    }
  },
  mounted() {
    this.getMatch(this.$route.params.id)
  },
  methods: {
    getMatch(matchId){
       fetch(`${process.env.VUE_APP_PRIVATE_API}/matches/${matchId}`)
      .then(response => response.json())
      .then(data => (this.match = data));
    }
  }
}
</script>