<template>
  <div class="schools">
    <schools-bar :schools="schools"></schools-bar>
  </div>
  <h1>{{ article.title }}</h1>
  <h3>{{ article.excerp }}</h3>
  <p>Autor: {{ article.authorName }}</p>
  <img :src="article.featuredImageUrl" alt="sports" />
  <p>{{ article.body }}</p>
</template>

<script>
export default {
  data() {
    return {
      article: {}
    }
  },
  mounted() {
    this.getArticle(this.$route.params.id)
  },
  methods: {
    getArticle(articleId){
       fetch(`${process.env.VUE_APP_PRIVATE_API}/articles/${articleId}`)
      .then(response => response.json())
      .then(data => (this.article = data));
    }
  }
}
</script>