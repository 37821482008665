<template>
  <div class="info">
    <div class="left">
      <img :src="match.homeSchoolLogo" />
      {{ match.homeTeamName }}
    </div>
    <div class="middle">
      <p>Fútbol Sub13 Masculino</p>
      <p>Fase de Grupos</p>
      <p>26 Feb 2022 - 15:00</p>
      <p>Estadio</p>
      <p>0 - 0</p>
    </div>
    <div class="right">
      <img :src="match.awaySchoolLogo" />
      {{ match.awayTeamName }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["match"]
}
</script>

<style scoped>
.info {
  display: inline-flex;
  background-color: white;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  width: 100%;
}
img {
  height: 100px;
}
.left {
  float: left;
}
.middle {
  float: left;
}
.right {
  float: right
}
</style>