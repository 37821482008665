<template>
  <div class="container">
    <div class="header">
      <match-info-bar :match="match"></match-info-bar>
    </div>
    <div class="main">
      <div class="video-player">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/rxslQS6KTS4?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="lineups">
        <div class="home-lineup">
          <soccer-lineup :players="players.home.starting"></soccer-lineup>
          <p>{{ match.homeTeamName }}</p>
          <p>{{ players.home.formation }}</p>
          <table>
            <tr>
              <th>Foto</th>
              <th>Nro.</th>
              <th>Nombre</th>
              <th>Posición</th>
            </tr>
            <tr v-for="player in players.home.starting" :key="player.playerId">
              <td><img :src="player.playerPictureUrl" /></td>
              <td>{{ player.number }}</td>
              <td>
                <router-link :to="'/player/' + player.playerId">{{
                  player.playerName
                }}</router-link>
              </td>
              <td>{{ player.position }}</td>
            </tr>
          </table>
        </div>
        <div class="away-lineup">
          <soccer-lineup :players="players.away.starting"></soccer-lineup>
          <p>{{ match.awayTeamName }}</p>
          <p>{{ players.away.formation }}</p>
          <table>
            <tr>
              <th>Foto</th>
              <th>Nro.</th>
              <th>Nombre</th>
              <th>Posición</th>
            </tr>
            <tr v-for="player in players.away.starting" :key="player.playerId">
              <td><img :src="player.playerPictureUrl" /></td>
              <td>{{ player.number }}</td>
              <td>
                <router-link :to="'/player/' + player.playerId">{{
                  player.playerName
                }}</router-link>
              </td>
              <td>{{ player.position }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="standings">
        <table>
          <tr>
            <th>Equipo</th>
            <th>PJ</th>
            <th>PG</th>
            <th>PE</th>
            <th>PP</th>
            <th>GF</th>
            <th>GC</th>
            <th>DIF</th>
            <th>PTS</th>
          </tr>
          <tr v-for="standing in standings" :key="standing.name">
            <td>{{ standing.name }}</td>
            <td>{{ standing.P }}</td>
            <td>{{ standing.W }}</td>
            <td>{{ standing.D }}</td>
            <td>{{ standing.L }}</td>
            <td>{{ standing.F }}</td>
            <td>{{ standing.A }}</td>
            <td>{{ standing.GD }}</td>
            <td>{{ standing.Pts }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="aside">
      <div class="ads">
        <ad-placeholder />
        <ad-placeholder />
        <ad-placeholder />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      match: {},
      standings: [],
      players: {
        home: {
          formation: "",
          starting: [],
          substitutes: [],
        },
        away: {
          formation: "",
          starting: [],
          substitutes: [],
        },
      },
    };
  },
  mounted() {
    this.getMatch(this.$route.params.id);
    this.getMatchPlayers(this.$route.params.id);
    this.getStandings();
  },
  methods: {
    getMatch(matchId) {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/matches/${matchId}`)
        .then((response) => response.json())
        .then((data) => (this.match = data));
    },
    getMatchPlayers(matchId) {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/matches/${matchId}/players`)
        .then((response) => response.json())
        .then((data) => (this.players = data));
    },
    getStandings() {
      fetch(`${process.env.VUE_APP_PRIVATE_API}/standings`)
        .then((response) => response.json())
        .then((data) => (this.standings = data));
    },
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 900px 300px;
  align-items: initial;
  row-gap: 20px;
}

.video-player {
  text-align: center;
  align-items: center;
  justify-items: center;
}

.header {
  grid-column-start: 1;
  grid-column-end: end;
}

.main {
  grid-column-start: 1;
  grid-column-end: 2;
}

.aside {
  grid-column-start: 2;
  grid-column-end: end;
  grid-row-start: 2;
  grid-row-end: end;
}

.ads {
  display: grid;
  max-height: 800px;
  row-gap: 10px;
}

.lineups {
  display: grid;
  grid-template-columns: 400px 400px;
  column-gap: 50px
}

.lineups img {
  height: 60px;
}

table {
  border-collapse:collapse; 
  text-align: center;
}

th {
  background-color: black;
  color: white;
}

td {
  border: 1px solid black;
}

.standings table {
  width: 700px;
}
</style>