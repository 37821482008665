<template>
    <div class="card">
        <div class="header">Categoría</div>
        <div class="featuredImage">
            <img :src="featuredImageUrl" alt="sports" />
        </div>
        <div class="footer">
            <div class="title">{{ title }}</div>
            <div class="excerp">{{ excerp }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["title", "excerp", "featuredImageUrl"]
}
</script>

<style scoped>

.card {
    padding: 15px;
    width: 600px;
    text-align: center;
    justify-items: center;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.category {
    width: 100%;
    font-size: 18px;
    font-weight: bolder ;
    background-color: white;
}
.title {
    font-size: 16pt;
    font-weight: bolder;
}
img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
}
</style>