import { createRouter, createWebHistory } from 'vue-router';

import HomePage from './pages/HomePage.vue';
import ListPage from './pages/ListPage.vue';
import PlayerPage from './pages/PlayerPage.vue';
import SchoolPage from './pages/SchoolPage.vue';
import TeamPage from './pages/TeamPage.vue';
import MatchPage from './pages/MatchPage.vue';
import EventPage from './pages/EventPage.vue';
import SoccerPage from './pages/SoccerPage.vue';
import BasketballPage from './pages/BasketballPage.vue';
import VolleyballPage from './pages/VolleyballPage.vue';
import SchoolsPage from './pages/SchoolsPage.vue';
import ArticlePage from './pages/ArticlePage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/home' },
    { path: '/home', component: HomePage },
    { path: '/list', component: ListPage },
    { path: '/soccer', component: SoccerPage },
    { path: '/schools', component: SchoolsPage },
    { path: '/basketball', component: BasketballPage },
    { path: '/volleyball', component: VolleyballPage },
    { path: '/player/:id', component: PlayerPage, props: true },
    { path: '/school/:id', component: SchoolPage, props: true },
    { path: '/team/:id', component: TeamPage, props: true },
    { path: '/event/:id', component: EventPage, props: true },
    { path: '/match/:id', component: MatchPage },
    { path: '/article/:id', component: ArticlePage, props: true },
  ],
});

export default router;
